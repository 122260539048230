import { useIntersectionObserver } from '@finn/ui-utils';
import React, { useEffect, useRef } from 'react';

interface IProps {
  onReached: Function;
}

const BottomMarker: React.FunctionComponent<IProps> = ({ onReached }) => {
  const markerRef = useRef<HTMLDivElement | null>(null);

  const isInView = useIntersectionObserver(markerRef, 0, '0px');
  useEffect(() => {
    if (isInView) {
      onReached();
    }
  }, [isInView, onReached]);

  return <div ref={markerRef} className="w-full" data-appid="bottom-marker" />;
};

export default React.memo(BottomMarker);
